import {Button, Card, CardBody, CardFooter, CardHeader, Divider, Input} from "@nextui-org/react";
import {Link} from "react-router-dom";
import {useState} from "react";
import OtpInput from 'react-otp-input';
import {toast, Toaster} from "sonner";

export const AuthPage = () => {
    const [otp, setOtp] = useState('');

    const sendCode = () => {
        if (otp === "8045") {
            localStorage.setItem("token", "true");
            window.location.reload();
        } else {
            toast.error("Ошибочка")
        }
    }

    return (
        <div className="w-full h-[100vh] flex justify-center items-center">
            <Toaster />
            <Card className="max-w-[400px]">
                <CardHeader className="max-w-[400px]">
                    <div className="flex flex-col">
                        <p className="text-md">MPSCAN</p>
                        <p className="text-small text-default-500">Управление поставками</p>
                    </div>
                </CardHeader>
                <Divider/>
                <CardBody>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span className="mx-2">-</span>}
                        renderInput={(props) => <Input {...props} />}
                    />
                </CardBody>
                <Divider/>
                <CardFooter className="justify-center">
                   <Button color="success" onPress={sendCode}>Вход</Button>
                </CardFooter>
            </Card>
        </div>
    )
}