import {Navigate, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "./config/axios.api";
import {AuthPage} from "./pages/auth/auth.page";
import {LayoutBase} from "./components/layout/layout";
import {HomePage} from "./pages/home/home.page";
import {CabinetPage} from "./pages/cabinet/cabinet.page";
import {MonitoringPage} from "./pages/monitoring/monitoring.page";

function App() {

  return (
    <Routes>
      <>
          {localStorage.getItem("token") == "true" ? <>
          <Route path="/" element={<LayoutBase/>}>
              <Route index element={<HomePage/>}/>
              <Route path="/lk" element={<CabinetPage/>}/>
              <Route path="/monitoring" element={<MonitoringPage/>}/>
          </Route>
        </> :
              <>
                  <Route path="/" element={<AuthPage/>}/>
              </>
        }
      </>
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
