import {Table, TableBody, TableCell, TableColumn, TableHeader, TableRow} from "@nextui-org/react";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../config/axios.api";
import {CloseIcon} from "../../icons/close.icon";
import {Link} from "react-router-dom";

const columns = [
    {name: "Склад", uid: "stockName"},
    {name: "Дата", uid: "dateSupple"},
    {name: "Макс. Коэффициент", uid: "maxCoefficient"},
    {name: "Ссылка", uid: "url"},
    {name: "Действия", uid: "actions"},
];

function formatDatePicker(dateStr) {
    const dateObj = new Date(dateStr);

    // Получаем день, месяц и год
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Месяцы в JS начинаются с 0
    const year = dateObj.getFullYear();

    // Формируем строку в нужном формате
    return `${day}-${month}-${year}`;
}

export const TableMonitoring = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(`${api}/monitoring?page=1`).then(x => setData(x.data.content))
    }, []);

    const deleteRow = (id) => {
        axios.delete(`${api}/monitoring?id=${id}`).then(x => {
            window.location.reload();
        })
    }

    const renderCell = useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "dateSupple":
                return (
                    <div>
                        {formatDatePicker(cellValue)}
                    </div>
                )
            case "url":
                return (
                    <Link to={cellValue} className="underline text-blue-500" target="_blank">Перейти</Link>
                )
            case "actions":
                return (
                    <div className="w-full flex justify-center">
                        <CloseIcon onClick={() => {deleteRow(user.id)}} className="cursor-pointer"/>
                    </div>
                )
            default:
                return cellValue;
        }
    }, []);

    return (
        <Table aria-label="Example table with custom cells">
            <TableHeader columns={columns}>
                {(column) => (
                    <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
                        {column.name}
                    </TableColumn>
                )}
            </TableHeader>
            <TableBody items={data}>
                {(item) => (
                    <TableRow key={item.id}>
                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}