import {MenuBar} from "../navBar/menu.jsx";
import {Outlet} from "react-router-dom";

export const LayoutBase = () => {
    return (
        <div className="p-5 flex flex-col justify-center items-center gap-5">
            <MenuBar/>
            <div className="w-full">
                <Outlet/>
            </div>
        </div>
    )
}