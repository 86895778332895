export const PencilIcon = ({...props}) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5"
                  d="M20.8482 8.71306C22.3839 7.17735 22.3839 4.68748 20.8482 3.15178C19.3125 1.61607 16.8226 1.61607 15.2869 3.15178L14.3999 4.03882C14.412 4.0755 14.4246 4.11268 14.4377 4.15035C14.7628 5.0875 15.3763 6.31601 16.5303 7.47002C17.6843 8.62403 18.9128 9.23749 19.85 9.56262C19.8875 9.57563 19.9245 9.58817 19.961 9.60026L20.8482 8.71306Z"
                  fill="#1C274C"/>
            <path
                d="M14.4386 4.00049L14.4004 4.03868C14.4125 4.07536 14.4251 4.11255 14.4382 4.15022C14.7633 5.08736 15.3768 6.31587 16.5308 7.46989C17.6848 8.6239 18.9133 9.23735 19.8505 9.56248C19.8876 9.57538 19.9243 9.58782 19.9606 9.59981L11.4001 18.1603C10.823 18.7374 10.5343 19.026 10.2162 19.2742C9.84082 19.567 9.43469 19.818 9.00498 20.0227C8.6407 20.1964 8.25351 20.3254 7.47918 20.5835L3.39584 21.9446C3.01478 22.0717 2.59466 21.9725 2.31063 21.6885C2.0266 21.4044 1.92743 20.9843 2.05445 20.6032L3.41556 16.5199C3.67368 15.7456 3.80273 15.3584 3.97634 14.9941C4.18114 14.5644 4.43213 14.1583 4.7249 13.7829C4.97307 13.4647 5.26165 13.1762 5.83874 12.5991L14.4386 4.00049Z"
                fill="#1C274C"/>
        </svg>
    )
}