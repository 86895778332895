import {
    Button,
    DatePicker,
    Input, Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    Select,
    SelectItem,
    useDisclosure
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../config/axios.api";
import {parseDate} from "@internationalized/date";
import {TableMonitoring} from "../../components/table_monitoring/tgableMonitoring";
import {toast, Toaster} from "sonner";

export const MonitoringPage = () => {

    const [stocks, setStocks] = useState();
    const [value, setValue] = useState(new Set([]));
    const [data, setData] = useState({
        selectedStock: null,
        maxCoefficient: 3,
        url: null,
        selectedDate: null
    });
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    useEffect(() => {
        axios.get(`${api}/stock`).then(x => {
            setStocks(x.data);
        })
    }, []);

    function formatDatePicker(dateObj) {
        const year = dateObj.year;
        const month = String(dateObj.month).padStart(2, '0'); // Добавление ведущего нуля для месяца
        const day = String(dateObj.day).padStart(2, '0');     // Добавление ведущего нуля для дня

        return `${year}-${month}-${day}`;
    }

    // Обработчики изменения значений
    const handleStockChange = (value) => {
        setData((prevData) => ({
            ...prevData,
            selectedStock: value
        }));
    };

    const handleCoefficientChange = (e) => {
        setData((prevData) => ({
            ...prevData,
            maxCoefficient: e.target.value
        }));
    };

    const handleDateChange = (value) => {
        setData((prevData) => ({
            ...prevData,
            selectedDate: value
        }));
    };

    const handleAdd = () => {
        const firstValue = value.values().next().value;
        const stock_id = firstValue.split(":")[0];
        const stock_name = firstValue.split(":")[1];
        const body = {
            stockId: stock_id,
            stockName: stock_name,
            url: data.url,
            maxCoefficient: data.maxCoefficient,
            dateSupple: data.selectedDate,
        }
        axios.post(`${api}/monitoring`, body).then(x => {
            toast.success("мониторинг добавлен")
            window.location.reload();
        });
        onOpenChange();
    };

    return (
        <div>
            <Toaster/>
            <div className="flex justify-end mb-2">
                <Button onPress={onOpen} color="primary">Добавить мониторинг</Button>
            </div>
                <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalHeader className="flex flex-col gap-1">Добавить мониторинг</ModalHeader>
                                <ModalBody>
                                    <Select selectedKeys={value} onSelectionChange={setValue} label="Склад" onChange={handleStockChange}>
                                        {stocks?.map(x => {
                                            return (
                                                <SelectItem key={x.stockWbId + ":" + x.stockName}>
                                                    {x.stockName}
                                                </SelectItem>
                                            )
                                        })}
                                    </Select>
                                    <Input
                                        type="number"
                                        label="Макс. Коэффициент"
                                        value={data?.maxCoefficient}
                                        onChange={handleCoefficientChange}
                                    />
                                    <Input
                                        label="Ссылка на поставку"
                                        value={data?.url}
                                        onChange={(e) => {
                                            setData((prevData) => ({
                                                ...prevData,
                                                url: e.target.value
                                            }));
                                        }}
                                    />
                                    <DatePicker onChange={(e) => setData(x =>( {...x, selectedDate: formatDatePicker(e)}))} value={data?.selectedDate != null ? parseDate(data?.selectedDate) : null}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" variant="light" onPress={onClose}>
                                        Закрыть
                                    </Button>
                                    <Button color="primary" onPress={handleAdd}>
                                        Добавить
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            <TableMonitoring/>
        </div>
    )
}