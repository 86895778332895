import {TableSupplies} from "../../components/table_supplies/tableSupplies";
import {useEffect, useState} from "react";
import {Tab, Tabs} from "@nextui-org/react";
import axios from "axios";
import {api} from "../../config/axios.api";

export const HomePage = () => {

    const [cabinets, setCabinets] = useState([]);

    useEffect(() => {
        axios.get(`${api}/lk`).then(x => {
            setCabinets(x.data);
        })
    }, []);

    return (
        <div>
            <Tabs>
                {cabinets?.map((x, i) => {
                    return(
                        <Tab key={i} title={x.name}>
                            <TableSupplies cabinet={x}/>
                        </Tab>
                    )
                })}
            </Tabs>

        </div>
    )
}