import axios from "axios";

export const api = "https://api.mpscan.ru";

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function fetchWithDelayGet(url, delayTime) {
    await delay(delayTime); // Искусственная задержка
    return await axios.get(url);
}

export async function fetchWithDelayPost(url, body, delayTime) {
    await delay(delayTime); // Искусственная задержка
    return await axios.post(url, body);
}