import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Chip, DatePicker,
    Divider, Input,
    Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader, Select, SelectItem, Textarea, useDisclosure
} from "@nextui-org/react";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../config/axios.api";
import {parseDate} from "@internationalized/date";
import {toast, Toaster} from "sonner";

export const CabinetPage = () => {

    const [data, setData] = useState();
    const [update, setUpdate] = useState(false);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {
        isOpen: isOpenEdit,
        onOpen: onOpenEdit,
        onClose: onOpenChangeEdit
    } = useDisclosure()
    const [cabinet, setCabinet] = useState();
    const [cabinetEdit, setCabinetEdit] = useState();

    useEffect(() => {
        axios.get(`${api}/lk`).then(x => {
            setData(x.data);
        })
    }, [update]);

    const handleAdd = () => {
        const body = {
            "apiToken": cabinet.api,
            "cookie": cabinet.cookie,
            "name": cabinet.name,
            "apiTokenStatus": "true",
            "cookieStatus": true,
        }
        axios.post(`${api}/lk`, body).then(x => {
            toast.success("Кабинет добавлен");
            window.location.reload();
        }).catch(x => {
            toast.error("При добавлении кабинета произошла ошибка")
        }).finally(() => setUpdate(!update))
    }

    const editCabinet = (x) => {
        setCabinetEdit(x);
        setCabinetEdit(prev => ({...prev, cookie : null}));
        setCabinetEdit(prev => ({...prev, apiToken : null}));
        onOpenEdit();
    }

    const updateCabinet = () => {
        const body = {
            "id": cabinetEdit.id,
            "apiToken" : cabinetEdit.apiToken,
            "cookie" : cabinetEdit.cookie,
            "name" : cabinetEdit.name,
        }
        axios.post(`${api}/lk`, body).then(x => {
            toast.success("Кабинет обновлен");
            window.location.reload();
        }).catch(x => {
            toast.error("При обновлении кабинета произошла ошибка")
        }).finally(() => setUpdate(!update))
    }

    return (
        <div>
            <Toaster/>
            <div className="flex justify-end">
                <Button color="primary" onClick={onOpen}>Добавить Кабинет</Button>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Добавить Кабинет</ModalHeader>
                            <ModalBody>
                                <Input label="Название" value={cabinet?.name} onChange={(e) => setCabinet(x => ({...x, name : e.target.value}))}/>
                                <Textarea label="Api токен" value={cabinet?.api} onChange={(e) => setCabinet(x => ({...x, api : e.target.value}))}/>
                                <Textarea label="Cookie токен" value={cabinet?.cookie} onChange={(e) => setCabinet(x => ({...x, cookie : e.target.value}))}/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onPress={handleAdd}>
                                    Добавить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenEdit} onOpenChange={onOpenChangeEdit}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Редактировать кабинет</ModalHeader>
                            <ModalBody>
                                <Input label="Название" value={cabinetEdit?.name} onChange={(e) => setCabinetEdit(x => ({...x, name : e.target.value}))}/>
                                <Textarea label="Api токен" value={cabinetEdit?.api} onChange={(e) => setCabinetEdit(x => ({...x, api : e.target.value}))}/>
                                <Textarea label="Cookie токен" value={cabinetEdit?.cookie} onChange={(e) => setCabinetEdit(x => ({...x, cookie : e.target.value}))}/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onPress={updateCabinet}>
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <div className="gap-2 grid grid-cols-2 sm:grid-cols-4">
                {data?.map((x) => {
                    return (
                        <Card shadow="sm" isPressable onPress={() => editCabinet(x)}>
                            <CardBody>
                                <div>
                                    <p className="text-md font-bold">{x.name}</p>
                                </div>
                                <Divider className="my-2"/>
                                <div className="flex justify-between mb-2">
                                    <div>
                                        <b>API</b>
                                        <p className="text-default-500">{x.apiToken}</p>
                                    </div>
                                    <Chip
                                        color={x?.apiTokenStatus ? "success" : "danger"}>{x?.apiTokenStatus ? "Активно" : "Протух"}</Chip>
                                </div>
                                <div className="flex justify-between items-center">
                                    <div>
                                        <b>Кука</b>
                                        <p className="text-default-500">{x.cookie}</p>
                                    </div>
                                    <Chip color={x?.cookieStatus ? "success" : "danger"}>{x?.cookieStatus ? "Активно" : "Протух"}</Chip>
                                </div>
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
        </div>
    )
}